$grid-size: 7px;
$header-background-color: #eee;
$editor-background-color-color: transparent !important;

@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material.scss';

.ag-theme-material {
  border: 1px solid $header-background-color;

  .ag-cell-inline-editing {
    height: 41px;
    top: 0;
  }

  .ag-cell-inline-editing, .ag-popup-editor {
    box-shadow: none;
    background-color: transparent;
    background: transparent;
    padding: 3px;
  }
}

.ag-disable-cell-highlight {
  &.ag-cell-focus, &.ag-cell-no-focus {
    border-color: transparent !important;
  }

  &.ag-cell:focus {
    border-color: transparent !important;
    outline: none;
  }
}
